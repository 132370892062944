import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios, { AxiosError } from "axios";
import { useSignOut } from 'react-auth-kit';

export const CreateUser = (props) => {

    const navigate = useNavigate();
    var { state } = useLocation();

    const [profilePicture, setprofilePicture] = useState(null);

    const [roles, setroles] = useState([]);
    const [brands, setbrands] = useState([]);


    const [selectBrandVisible, setselectBrandVisible] = useState(false);
    const [invalidAlert, setinvalidAlert] = useState(false);
    const [invalidAlertText, setinvalidAlertText] = useState('');

    //Listeners

    function convertToBase64(e) {

        try {
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                setprofilePicture(reader.result)

            }
        }
        catch (e) {

        }

    }

    function onChangeRole(e) {

        if (e.target.value == 'role_brand') setselectBrandVisible(true)
        else setselectBrandVisible(false)

    }

    const register = async () => {
        setinvalidAlert(false);
        setinvalidAlertText('');
        const baseURL = `/admin/user/`;

        axios.post(baseURL, getFormValues(), { withCredentials: true }).then((res) => {
            if (res.status !== 200) { }
            else {
                alert('Success');
                navigate('/allusers')
            }

        })
            .catch((err) => {
                setinvalidAlert(true)
                setinvalidAlertText(err.response.data.error)
            });


    }

    const getRoles = async () => {

        const baseURL = `/admin/roles`;
        axios.get(baseURL, { withCredentials: true }).then((res) => {
            if (res.status !== 200) { }
            else {
                setroles(res.data)
            }

        })
            .catch((err) => {

            });

    }

    const getBrands = async () => {

        const baseURL = `/merchant/brands`;
        axios.get(baseURL, { withCredentials: true }).then((res) => {
            if (res.status !== 200) { }
            else {
                console.log(res.data)
                setbrands(res.data)
            }

        })
            .catch((err) => {

            });

    }

    const getFormValues = () => {

        try {
            const userObj = {
                'password': document.getElementById('password').value,
                'role': document.getElementById('role').value,
                'subLevel': document.getElementById('subLevel').value,
                'displayName': document.getElementById('displayName').value,
                'positionRole': document.getElementById('positionRole').value,
                'email': document.getElementById('email').value.trim(),
                'brandId': (document.getElementById('brandId').value ? document.getElementById('brandId').value : ''),
                'profilePicture': profilePicture,
            };
            return userObj;
        }
        catch (e) {
            console.log(e)

        }
    }



    useEffect(() => {

        getRoles();
        getBrands();
    }, []);
    return (<>

        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">

            <div className="relative overflow-x-auto mt-10">

                <form className="w-full max-w-lg">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                Display Name
                            </label>
                            <input id="displayName" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="" />
                            <p className="text-gray-600 text-xs italic">Can be your Firstname + Lastname or any name you want to display</p>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Email
                            </label>
                            <input id="email" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="email" placeholder="" />
                            <p className="text-gray-600 text-xs italic">User will receive confirmation email</p>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                Password
                            </label>
                            <input id="password" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="password" placeholder="******************" />
                            <p className="text-gray-600 text-xs italic">Minimum of 8 characters, one special character, one uppercase, at least one number and symbol</p>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                Role
                            </label>
                            <div className="relative">
                                <select onChange={onChangeRole} id="role" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" >
                                    {roles.map((role) => (
                                        <>
                                            <option value={role.roleId}>{role.displayName}</option>
                                        </>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        </div>
                        <div className={`w-full md:w-1/3 px-3 mb-6 md:mb-0 ${selectBrandVisible ? '' : 'hidden'}`}>
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                Brand
                            </label>
                            <div className="relative">
                                <select id="brandId" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" >
                                    {brands.map((brand) => (
                                        <>
                                            <option value={brand.brandId}>{brand.name}</option>
                                        </>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        </div>
                        <div className={`w-full md:w-1/3 px-3 mb-6 md:mb-0 ${selectBrandVisible ? '' : 'hidden'}`}>
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                Sub Level
                            </label>
                            <div className="relative">
                                <select id="subLevel" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" >
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6 mt-5">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Position in company
                                </label>
                                <input id="positionRole" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Developer" />
                            </div>
                        </div>
                    <div className="flex flex-wrap -mx-3 mb-6 mt-5">
                        <div className="w-full px-3">
                            <img className="w-10 h-10 rounded" src={profilePicture} />
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                                Display picture
                            </label>
                            <input onChange={convertToBase64} accept="image/png" type="file"></input>

                        </div>

                    </div>
                </form>
                <button className="mb-6 bg-[#bb4d2a] hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={register}>
                    Register
                </button>
                <div className={`${invalidAlert ? '' : 'hidden'}`} role="alert">
                    <div className="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-2.5 py-1 shadow-md" role="alert">
                        <div className="flex">
                            <div><svg className="fill-current h-6 w-6 text-red-500 mr-1 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                            <div>
                                <p className="text-sm">{invalidAlertText}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </>)
}