import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from "axios";


export const CreateQR = (props) => {

    const navigate = useNavigate();
    var { state } = useLocation();;

    //console.log('Transcripts props', props);

    const { channel } = state;

    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const handleSubmit = async () => {
        if (!url) return alert('Please enter a URL');

        setLoading(true);
        setImageUrl(null);

        const baseURL = `/util/createqr`;

        axios.post(baseURL, { url, locale: channel.locale, channel_id: channel.channel_id }, { withCredentials: true }).then((res) => {
            setLoading(false);
            if (res.status !== 200) {
                alert('error');
            }
            else {
                setImageUrl(res.data.url);
            }

        })
            .catch((err) => {

            });
    };

    const resetInputs = async () => {
        setImageUrl(null);
        setLoading(false);
        setUrl('');
    };

    //Listeners
    useEffect(() => {

    }, []);
    return (<>

        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <h1 className="mb-4 text-2xl font-extrabold leading-none text-center tracking-tight text-gray-900 md:text-2xl lg:text-4xl dark:text-gray mt-10">Create QR</h1>
            <h1 className="mb-4 text-xl font-extrabold leading-none text-center tracking-tight text-gray-900 md:text-xl lg:text-2xl dark:text-gray mt-10"><span className="text-green-900">Channel</span> : {channel.channel_id}</h1>
            <h1 className="mb-4 text-xl font-extrabold leading-none text-center tracking-tight text-gray-900 md:text-xl lg:text-2xl dark:text-gray mt-2"><span className="text-green-900">Locale</span> : {channel.locale}</h1>
            <div className="p-4">
                <input
                    type="text"
                    placeholder="Enter URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="border border-gray-300 rounded py-2 px-4 w-full mb-4"
                />
                <button
                    onClick={handleSubmit}
                    disabled={loading}
                    className={`bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring ${loading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                >
                    {loading ? (
                        <span className="flex items-center">
                            <svg
                                className="animate-spin h-5 w-5 mr-2 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                ></path>
                            </svg>
                            Loading...
                        </span>
                    ) : (
                        'Submit'
                    )}
                </button>

                <button
                    onClick={resetInputs}
                    className={`bg-red-500 text-white font-bold py-2 px-4 ml-4 rounded hover:bg-black-600 focus:outline-none focus:ring`}
                >Reset</button>

                {imageUrl && (
                    <div className="mt-4">
                        <img src={imageUrl} alt="Returned from API" className="max-w-full h-auto mb-4" />

                        {/* Download Button */}
                        <a
                            href={imageUrl}
                            download="downloaded-image"
                            className="bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring"
                        >
                            Download Image
                        </a>
                    </div>
                )}

                {/* Show response */}
                {response && <div className="mt-4 p-4 bg-gray-100">{JSON.stringify(response)}</div>}
            </div>
        </div>
    </>)
}