import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from "axios";
import { useSignOut } from 'react-auth-kit';

export const Dashboard = (props) => {

    const navigate = useNavigate();
    var { state } = useLocation();
    const signOut = useSignOut();

    console.log('Dashboard props', props);

    return (<>
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">

            <div className="relative overflow-x-auto mt-10">
                <section className="bg-white dark:bg-gray-900">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
                        <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Welcome</h1>
                        <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">{props.userDetails.displayName}</p>
                        <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">Position : {props.userDetails.positionRole}</p>
                        
                    </div>
                </section>
            </div>
        </div>
    </>)
}