import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from "axios";


export const Lexicon = (props) => {

    const navigate = useNavigate();
    var { state } = useLocation();;

    //console.log('Transcripts props', props);

    const { channel } = state;

    const style = { 'background': 'transparent', 'border': '1px solid #ccc' }
    const src = "https://airtable.com/appxSEywaetcIdEfN/shrFLBVhW0StVL4DO"

    //Listeners
    useEffect(() => {

        console.log(channel.assets.Lexicon)

    }, []);
    return (<>

        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <h1 className="mb-4 text-4xl font-extrabold leading-none text-center tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-gray mt-10"><span className="text-green-900">Lexicon</span> : {channel.displayName}</h1>
            {(channel.assets.Lexicon?.airtable?.views)?
            Object.keys(channel.assets.Lexicon?.airtable?.views).map((view) => {
                return (<>
                    <div>
                        <h2 className="text-2xl font-extrabold leading-none text-center tracking-tight text-gray-900 mt-10 mb-5">{view}</h2>
                        <iframe class="airtable-embed" src={`https://airtable.com/embed/${channel.assets.Lexicon.airtable.AIRTABLE_VUI}/${channel.assets.Lexicon.airtable.views[view]}?backgroundColor=gray&viewControls=on`} frameborder="0" onmousewheel="" width="100%" height="533" style={{ background: "transparent", border: "1px solid #ccc" }}></iframe>
                    </div>
                </>)
            })
            :''}
        </div>
    </>)
}