import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios, { AxiosError } from "axios";
import { useSignOut } from 'react-auth-kit';

export const Allusers = (props) => {

    const [users, setusers] = useState([]);
    const [element, setelement] = useState('');


    useEffect(() => {

        fetchUsers();

    }, [element]);

    function fetchUsers() {
        const baseURL = `/admin/users`;

        axios.get(baseURL, { withCredentials: true }).then((res) => {
            if (res.status !== 200) { }
            else {
                //console.log('users', res.data)
                setusers(res.data)
            }

        })
            .catch((err) => {

            });

    }
    function handleDelete(username) {
        const baseURL = `/admin/user/${username}`;

        axios.delete(baseURL, { withCredentials: true }).then((res) => {
            if (res.status !== 200) { }
            else {
                alert("Success");
                fetchUsers();
            }

        })
            .catch((err) => {

            });
    }

    function handleResetPassword(username) {
        const baseURL = `/admin/user/${username}/reset`;

        axios.get(baseURL, { withCredentials: true }).then((res) => {
            if (res.status !== 200) { }
            else {
                alert("Success");
            }

        })
            .catch((err) => {

            });
    }

    function updateUserParameters(username, data) {
        const baseURL = `/admin/user/${username}`;

        axios.patch(baseURL, data, { withCredentials: true }).then((res) => {
            if (res.status !== 200) { }
            else {
                alert("Success");
                fetchUsers();
            }

        })
            .catch((err) => {

            });
    }
    return (<>
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">

            <div className="relative overflow-x-auto mt-10">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Display Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Display Picture
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Role
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Sub Level
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Brands
                            </th>
                            <th scope="col" className="px-6 py-3">
                                username
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Reset Password
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Actions
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.displayName}
                                </th>
                                <td className="px-6 py-4">
                                    <img className="w-10 h-10 rounded" src={user.profilePicture} />
                                </td>
                                <td className="px-6 py-4">
                                    {user.role}
                                </td>
                                <td className="px-6 py-4">
                                    {props.userDetails.role == 'role_super' ? (
                                        <>
                                            {(user.role != 'role_admin' && user.role != 'role_super') ? (
                                                <>
                                                    <select name="subLevel" id="subLevel" defaultValue={user.subLevel} onChange={(e) => updateUserParameters(user.username, {subLevel:e.target.value})}>
                                                        <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                    </select>
                                                </>
                                            ) : <>


                                            </>}

                                        </>
                                    ) : `${user.subLevel}`}
                                </td>
                                <td className="px-6 py-4">
                                    <ol>
                                    {user.brands.map((brand) => (
                                            <li>{brand.name}</li>
                                    ))}
                                    </ol>
                                </td>
                                <td className="px-6 py-4">
                                    {user.username}
                                </td>

                                <td className="px-6 py-4">
                                    {props.userDetails.role == 'role_super' ? (
                                        <>
                                            {(user.role != 'role_admin' && user.role != 'role_super') ? (
                                                <>
                                                    <button className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-yellow-800 hover:bg-gray-700 inline-flex items-center" onClick={() => handleResetPassword(user.username)}>Reset password</button>

                                                </>
                                            ) : <>


                                            </>}

                                        </>
                                    ) : ''}

                                </td>

                                <td className="px-6 py-4">
                                    {props.userDetails.role == 'role_super' ? (
                                        <>
                                            {(user.role != 'role_admin' && user.role != 'role_super') ? (
                                                <>
                                                    <button className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-red-800 hover:bg-gray-700 inline-flex items-center" onClick={() => handleDelete(user.username)}>Delete</button>

                                                </>
                                            ) : <>


                                            </>}

                                        </>
                                    ) : ''}


                                </td>

                            </tr>

                        ))}
                    </tbody>
                </table>
            </div>

        </div>

    </>)
}